import { type FunctionComponent, memo, useEffect, useMemo } from 'react';
import { Spinner } from 'yoc-ui-library';

import {
  filterAdDataBySlotIds,
  getSlotIdsFromString,
  isSlotIdMultiple,
  isSlotIdValid,
  splitSlotIdsFromUrl,
} from '@common/helpers';
import { useAdsStore } from '@common/stores';
import BasicView from '@components/ViewBasic';
import GalleryView from '@components/ViewGallery';

import { TViewProps } from './types';

const View: FunctionComponent<TViewProps> = (props) => {
  const { slotId: slotIdString } = props;

  const slotIdArray = slotIdString ? getSlotIdsFromString(slotIdString) : null;
  const isMultipleSlotId = isSlotIdMultiple(slotIdString);

  const adsDataCollection = useAdsStore((state) => {
    return state.adsData;
  });

  const activeSlotId = slotIdArray?.[0] ?? slotIdString; // TEMP???

  const singleAdData = adsDataCollection && !isMultipleSlotId ? adsDataCollection[activeSlotId] : null;
  const isGalleryMode =
    singleAdData && 'allowEffects' in singleAdData
      ? singleAdData.allowEffects && singleAdData.allowEffects.length > 0
      : isMultipleSlotId;

  const slotIds = useMemo(() => {
    return splitSlotIdsFromUrl(slotIdString).filter((slotId) => {
      return isSlotIdValid(slotId);
    });
  }, [slotIdString]);

  /* Fetch Ad data by SlotId */
  useEffect(() => {
    if (slotIds.length !== 0) {
      useAdsStore.getState().fetchAds(slotIds);
    }
    return () => {
      useAdsStore.getState().setAdsData(undefined); // cleanup
    };
  }, [slotIds]);

  // Initial state - fetching data
  if (adsDataCollection === undefined) {
    return (
      <div className="main">
        <Spinner />
      </div>
    );
  }

  // If there is no data or there is no valid slotId
  if (adsDataCollection === null) {
    return (
      <div className="main">
        <h1>NO CREATIVE FOUND</h1>
        <p>Unfortunately, there is no Creative to display 🤧</p>
      </div>
    );
  }

  return isGalleryMode ? (
    <GalleryView adsData={filterAdDataBySlotIds(adsDataCollection, slotIdArray)} />
  ) : (
    <BasicView adData={adsDataCollection[activeSlotId]} />
  );
};

export default memo(View);
