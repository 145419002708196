import { E_AD_CATEGORY_NAME, E_EFFECT, E_PREVIEW_MODE } from '@common/enums';

export const EFFECT_TITLE_NAME = {
  [E_EFFECT.NONE]: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
  [E_EFFECT.INLINE_TO_STICKY]: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
  [E_EFFECT.UNDERSTITIAL_MINI]: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
  [E_EFFECT.UNDERSTITIAL_MINI_TO_STICKY]: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
  [E_EFFECT.STICKY]: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
  [E_EFFECT.NONE_IN_STICKY]: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
  [E_EFFECT.FULLSCREEN_OVERLAY]: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
  [E_EFFECT.SITEBAR]: E_AD_CATEGORY_NAME.YOC_SITEBAR,
  [E_EFFECT.NONE_IN_SITEBAR]: E_AD_CATEGORY_NAME.YOC_NONE_IN_SITEBAR,
  [E_EFFECT.ZOOM]: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
  [E_EFFECT.ZOOM_TO_STICKY]: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
  [E_EFFECT.BRANDED_PLAYER]: E_AD_CATEGORY_NAME.YOC_BRANDED_PLAYER,
};

export const NARROW_DESKTOP_PREVIEW_EFFECTS: E_EFFECT[] = [
  E_EFFECT.NONE,
  E_EFFECT.INLINE_TO_STICKY,
];

export const DEVICE_RELATED_EXCLUDE_EFFECTS = {
  [E_PREVIEW_MODE.DESKTOP]: [
    E_EFFECT.UNDERSTITIAL_MINI,
    E_EFFECT.UNDERSTITIAL_MINI_TO_STICKY,
    E_EFFECT.FULLSCREEN_OVERLAY,
  ],
  [E_PREVIEW_MODE.MOBILE]: [E_EFFECT.SITEBAR],
};

// TODO: TEST it as opposite implementation of DEVICE_RELATED_EXCLUDE_EFFECTS
export const DEVICE_SPECIFIC_EFFECTS = {
  [E_PREVIEW_MODE.MOBILE]: [
    E_EFFECT.UNDERSTITIAL_MINI,
    E_EFFECT.UNDERSTITIAL_MINI_TO_STICKY,
    E_EFFECT.FULLSCREEN_OVERLAY,
  ],
  [E_PREVIEW_MODE.DESKTOP]: [E_EFFECT.SITEBAR],
};
