import { isMobileOnly } from 'react-device-detect';

import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCollection } from '@common/types';

export const getDefaultSlotId = (collectionData: TCollection, isMobile: boolean = false) => {
  // Use first found slotId from default category. If there is no default category, use first slotId from the list
  const defaultAd = collectionData.ads.find((item) => {
    const isDefaultCategory =
    item?.category === (collectionData?.defaultCategory ?? E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER);
    const isNotDesktop = item.displayMode !== 'desktop';
    const isValidDisplayMode = isMobile ? isNotDesktop : (!isMobileOnly || isNotDesktop);

    return isDefaultCategory && isValidDisplayMode;
  });

  return defaultAd?.slotId ?? collectionData.ads[0].slotId;
};
