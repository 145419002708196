import { CSSProperties } from 'react';

import {
  E_AD_CATEGORY_NAME,
  E_COUNTRY_CODE,
  E_CUSTOM_CODE,
  E_EFFECT,
  E_PREVIEW_MODE,
  TPreviewTheme,
} from './enums';

export type TSlotId = string;

export type TCollectionId = `${E_COUNTRY_CODE | E_CUSTOM_CODE}`;

export type TShowcaseType = 'collection';
export type TShowroomType = 'showroom-collection';

export type TCollection = {
  ads: TCreative[];
  type: TShowcaseType | TShowroomType;
  tag?: string;
  defaultCategory?: E_AD_CATEGORY_NAME;
};

// Config (JSON) Data
export type TCreative = {
  category: E_AD_CATEGORY_NAME;
  slotId: TSlotId;
  title: string;
  displayMode: 'mobile' | 'desktop'; // @deprecated - https://yocmobile.atlassian.net/browse/DC-363
  logoUrl?: string;
  backgroundUrl?: string;
};

// Redis (API) Data
export type TAdData = {
  allowEffects: E_EFFECT[];
  previewType: E_PREVIEW_MODE; // @deprecated
  previewWebsiteLayout: TPreviewTheme;
  productKey: E_AD_PRODUCT_KEY;
  restrictShowcasePreview: 'mobile-and-desktop' | 'desktop-only' | 'mobile-only';
  productName: string | null;
  sourceOfAsset: TSourceOfAsset; // probably this field belongs to productKey === TPA only. clarify this.
};

type TSourceOfAsset =
  | 'disabled'
  | 'auto'
  | 'default'
  | 'celtra-banner'
  | 'celtra-banner-sticky'
  | 'celtra-interscroller'
  | 'celtra-interstitial'
  | 'celtra-background-skin'
  | 'adtron-banner'
  | 'adtron-inline'
  | 'adtron-sticky'
  | 'adtron-interstitial'
  | 'unknown';

export type TAdDataBase = null | {
  [key: TSlotId]: TAdData;
};

export enum E_AD_PRODUCT_KEY {
  YBC = 'YBC',   // YOC Banner Classic
  YUA = 'YUA',   // YOC Understitial Ad
  YMA = 'YMA',   // YOC Mystery Ad
  TPA = 'TPA',   // Third Party Ad (YOC Mystery Scroller, YOC Mystery Ad @ Celtra, Adtron, etc.)
  UAF = 'UAF',   // Universal Ad Format (YUVS or YIVA)
  YS = 'YS',     // YOC Sitebar
  YZA = 'YZA',   // YOC Zoom Ad
  YIVA = 'YIVA', // YOC Inline Video Ad
  YISV = 'YISV', // YOC In-Stream Video Ad
  YUVS = 'YUVS', // YOC Universal Video Solution
  NA = 'UNSPECIFIED',
}

export type TimeZone = {
  timeZone: string;
  code: E_COUNTRY_CODE;
};

export type TCSSCustomProperty = CSSProperties & {
  [key: `--${string}`]: string;
};

export type Iterable<T> = {
  [key: string]: T;
};
