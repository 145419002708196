import { useCallback, useMemo, useState } from 'react';

import { E_AD_CATEGORY_NAME, TAdCategoryName } from '@common/enums';
import type { Iterable, TCreative } from '@common/types';

import type { TMenuProps } from './types';

export const useGroupedAds = (
  creatives: TMenuProps['creatives'],
  onCreativeChange: TMenuProps['onCreativeChange'],
  activeSlotId: TMenuProps['activeSlotId'],
  isMobile: TMenuProps['isMobile'],
) => {
  const [activeCategory, setActiveCategory] = useState<TAdCategoryName | undefined>(() => {
    const groups = creatives.filter((ad) => {
      return ad.slotId === activeSlotId;
    });

    return groups[0]?.category;
  });

  const groupedAds = useMemo(() => {
    if (!creatives) {
      return [];
    }

    const groupedCreatives = creatives.reduce((acc, currentValue) => {
      const groupKey = currentValue.category;
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    }, {} as Iterable<TCreative[]>);

    // keep original order from config
    const categoryOrder: E_AD_CATEGORY_NAME[] = [];
    for (const ad of creatives) {
      if (!categoryOrder.includes(ad.category)) {
        categoryOrder.push(ad.category);
      }
    }

    return categoryOrder.map((categoryName) => {
      return {
        categoryName,
        ads: groupedCreatives[categoryName],
      };
    });
  }, [creatives]);

  const onToggleCategory = useCallback((categoryName: TAdCategoryName) => {
    if (activeCategory === categoryName) {
      setActiveCategory(undefined); // closed the category
    } else {
      setActiveCategory(categoryName);

      if (typeof onCreativeChange === 'function' && !isMobile) {
        // find Ads by category
        const categoryAds = groupedAds?.find((item) => {
          return item.categoryName === categoryName;
        });

        if (categoryAds) {
          onCreativeChange(
            categoryAds.ads[0].slotId,
            categoryAds.ads[0].category,
            categoryAds.ads[0].displayMode === 'desktop'
          );
        }
      }
    }
  }, [activeCategory, groupedAds, isMobile, onCreativeChange]);

  return {
    groupedAds,
    activeCategory,
    onToggleCategory,
  };
};
