import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_YZA: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'egBQ-j4qL-8TJC',
    title: 'With background image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/ad/showroom/yza/compressed/background/yza_rauch.jpg',
    logoUrl: 'https://cdn.yoc.com/ad/showroom/yza/compressed/logo/rauch.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'Qf6e-EXI7-M3UY',
    title: 'Automatic background',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/ad/showroom/yza/compressed/background/yza_audi.jpg',
    logoUrl: 'https://cdn.yoc.com/ad/showroom/yza/compressed/logo/audi.png',
  },
];
