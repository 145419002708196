import { FunctionComponent } from 'react';
import { Route } from 'type-route';

import { AVAILABLE_COUNTRIES } from '@common/constants';
import { routes, useRoute } from '@navigation/router';

import style from './styles.module.scss';

const Navigation: FunctionComponent = () => {
  const route = useRoute();

  return (
    <ul
      className={style.nav}
      data-testid="navigation"
    >
      {AVAILABLE_COUNTRIES.map((countryId) => {
        const isActive = countryId.toLowerCase() === (route as Route<typeof routes.collection>).params.collectionId;
        return (
          <li key={countryId}>
            <a
              className={isActive ? style.active : ''}
              {...routes.collection({ collectionId: countryId }).link}
            >
              {countryId.toUpperCase()}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Navigation;
