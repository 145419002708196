import { CSSProperties, FunctionComponent, memo } from 'react';

import { E_ARROW_TEXT, TButtonArrowProps } from './types';

import style from './styles.module.scss';

const ArrowButton: FunctionComponent<TButtonArrowProps> = (props) => {
  const {
    direction = 'left',
    className,
    color = '#fff',
    disabled = false,
    scale = 1,
    onClick,
  } = props;

  return (
    <button
      data-testid="button-arrow"
      className={`${className ?? ''} ${style.arrow} ${style[direction]} ${disabled ? style.disabled : ''}`.trim()}
      style={{
        '--scale': scale.toString(),
        '--color': color,
      } as CSSProperties}
      onClick={onClick}
    >
      {E_ARROW_TEXT[direction]}
    </button>
  );
};

export default memo(ArrowButton);
