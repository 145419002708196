import { E_PREVIEW_MODE } from '@common/enums';

import { TBasicViewProps } from './types';

export const getPreviewType = (adData: TBasicViewProps['adData']) => {
  if (adData) {
    const {
      restrictShowcasePreview: restrictPreview,
      previewType,
    } = adData;

    if (previewType === 'desktop' || restrictPreview === 'desktop-only') {
      return E_PREVIEW_MODE.DESKTOP;
    } else if (
      previewType === 'mobile' &&
      (restrictPreview === 'mobile-only' || restrictPreview === 'mobile-and-desktop')
    ) {
      return E_PREVIEW_MODE.MOBILE;
    }
  }

  return undefined;
};
