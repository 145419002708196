import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_PHARMA: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'bTre-TzXQ-XUUK',
    title: 'Shoppable Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_GSKVoltadol.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Voltadol.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'dZyV-amDe-qONk',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/YUVA_Stepsils.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Strepsils.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'CUgt-bDGy-jXAD',
    title: 'Fullscreen Cube',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_GaldermaCetaphil.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Cetaphil.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'TWFT-44QB-fenw',
    title: 'Quiz',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_NestleHealthcare.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Nestle_health_science.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'P9Zx-7IU2-gipx',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl:
      'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_WipeAway%2BDrag&Drop_GaldermaCetaphil.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Cetaphil.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'qest-g9TY-gNmb',
    title: 'Wipe Away',
    displayMode: 'mobile',
    backgroundUrl:
      'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_WipeAway%2BDrag&Drop_GaldermaCetaphil.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Cetaphil.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Zsvy-0hpq-qh10',
    title: 'Hot Spots',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_Priorin.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Bayer.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '8rr1-prnB-Ldgk',
    title: 'Wipe Away',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_Klosterfrau.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Klosterfrau.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'dVqj-XgVL-3yKO',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_Efasit.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Efasit.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'V1tB-acsO-8Fh9',
    title: 'Match',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_Durex.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Durex.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'XR1O-Dl5Q-pqVj',
    title: 'Wipe Away + Map',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_Hansaplast.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Hansaplast.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'SNi7-NYYL-UlgC',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_Ratiopharm.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_ratiopharm.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'NUxa-tJcf-ciUX',
    title: 'Fullscreen',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_KetonalActive.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Sandoz.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'vDgN-VHrS-1L2q',
    title: 'shake',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_BiorythmShake.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Biorythm.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'sKul-FPyy-nbbQ',
    title: 'Shoppable Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_GSKVoltadol.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Voltadol.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'NBY9-3vE0-IMFp',
    title: 'Slider',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryScroller_BionoricaSinupret.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-bionorica.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: '6ry1-F5Sp-wgO8',
    title: 'Shoppable Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/MysteryAd_SANOFIMucosolvanSilomat.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Silomat.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'TiW9-jJCY-XcR4',
    title: 'Video Integration',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/InlineVideo_NiveaSkin.jpg',
    logoUrl: 'https://cdn-origin.yoc.com/at/showroom/at/logo/nivea-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '4GFy-UYsU-yjKT',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/Understitial_GaldermaCetaphil.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Cetaphil.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'PB0q-MC5x-mxNV',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/SkinKombi_Berocca.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Bayer.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'RKc2-MKE0-w7Ek',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/SkinKombi_Berocca.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Bayer.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'GeDQ-mH1H-qQYn',
    title: 'Branded Player',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Background/InlineVideo_NiveaSkin.jpg',
    logoUrl: 'https://cdn-origin.yoc.com/at/showroom/at/logo/nivea-logo.png',
  },
];
