import { FunctionComponent, memo } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { E_PREVIEW_THEME } from '@common/enums';
import { useDmexco } from '@common/hooks';
import { useThemeStore } from '@common/stores';
import Navigation from '@components/Navigation';

import { IHeaderProps } from './types';

import style from './styles.module.scss';

const Header: FunctionComponent<IHeaderProps> = (props) => {
  const { navigation = <Navigation />, tag = 'Showroom' } = props;
  const { theme } = useThemeStore();

  const isDMEXCOMode = useDmexco();

  return (
    <header
      className={style.header}
      data-testid="header"
    >
      {(isMobileOnly && !isDMEXCOMode) && (
        <button
          className="back-arrow"
          onClick={() => {
            window.location.href = window.location.href.replace('preview/','');
          }}
        />
      )}
      <span
        className="tag"
        data-testid="tag"
      >
        {tag}
      </span>
      {theme === E_PREVIEW_THEME.YOC && navigation}
    </header>
  );
};

export default memo(Header);
