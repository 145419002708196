/* eslint-disable max-lines */
import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_DE: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'Ex1h-nAys-jOrF',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/LAVAZZA-BANNER.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/lavazza-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'ti2S-Wki0-eUSN',
    title: 'Blurred Background',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/VAILLANT.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/VAILLANT.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: '4g5O-D0qX-mKie',
    title: 'Mystery Ad - Overlay',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/ICE.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/ICE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '3lfa-iZ0l-Vre5',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/SONIC.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/PARAMOUNT.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'dZ6U-Ol8B-aUMH',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/NESPRESSO-VERTUO-POP.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/NESPRESSO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'pDzt-WO8g-OV9E',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/Kabel1-Roadtrip-America.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/Logo_Kabel1.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'L9n1-hnUl-UZyr',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/6-KFC.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/KFC.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '1sNT-ixyI-6eBH',
    title: 'Tune',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/MMSxOralB.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/Logo_Oral-B.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'rUEo-e9HD-tFZA',
    title: 'Choose',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/Tchibo-Qbo.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/TCHIBO.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'o6DB-OxSe-lxKW',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/STUDIOCANAL-GPM.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/STUDIOCANAL.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'ULXc-Jc4j-CLd6',
    title: 'Incoming Call',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/LEGO.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/LEGO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'MIqS-1seK-IuTP',
    title: 'Wipe Away',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/DISNEY-NUSSKNACKER.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/DISNEY.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'IvGC-VAnD-mtP2',
    title: 'Slider',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/12-Panerai-Radiomir.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/PANEREI.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '6Kq9-mc00-FEwJ',
    title: 'Hot Spots',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/Hyundai-Kona.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/Hyundai.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'dksl-wo5A-Ekf5',
    title: 'Match',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/SKY-VALENTINES.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/SKY.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'iZRt-G4Sr-dqxE',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/NESPRESSO-FESTIVE.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/NESPRESSO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Nrr3-ul2g-zUPk',
    title: 'Swipe',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/KITKAT.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/KITKAT.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'vNPy-64Ym-FyyM',
    title: 'Touch',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/Iglo-GreenCuisine.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/Iglo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'XRDg-Iu7p-AAxs',
    title: 'Slidebar',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/18-Disney-Frozen.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/DISNEY.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'urEc-HfHW-fs7F',
    title: 'Poll',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/CINI-MINIS.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/NESTLE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'WGtS-Vcnb-JHNw',
    title: 'Quiz',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/ProSieben-WerIsses.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/ProSieben.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'aHQ9-8SaZ-HjFG',
    title: 'Shake',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/21-RTL-LOW.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/RTL.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'FE34-wE1b-VfRw',
    title: 'Panorama',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/HASBRO-PEPPA-PIG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/HASBRO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'fKKp-eDBc-lwHE',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/FUJIFILM.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/FUJIFILM.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'bxwU-dppN-iPY9',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/DEKA.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/DEKA.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'nNw6-UoP0-TXwQ',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/26-Citroen-Aircross.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/CITROEN.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: '5Gah-KzMG-b4KI',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/BIG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/BIG-BOBBYCAR.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'a5lM-II4w-dj7s',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/28-AMEX.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/AMEX.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'qBxh-IGVz-71qE',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/LEGO-Scroller.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/LEGO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: '3WQh-J3YU-fpmo',
    title: 'Gallery',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/30-Cyberport-Aplle.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/CYBERPORT.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'EGsu-WREw-xESY',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/ICE.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/ICE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'KKPs-Fx2o-TY6N',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/LADIVA.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/DONNA.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'VLkU-ZwNN-cELZ',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/LANCOME.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/LANCOME.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: '2Aqf-9gXb-OhIz',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/MBC-EQS-SUV.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/MBC.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'poHn-jr9s-aRto',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/PLAYSTATION.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/PLAYSTATION.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'myg4-MpAR-l9AH',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/NIPPON.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/HOSTA.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'KGf8-NhhQ-NQHw',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/PANDORA.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/PANDORA.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'l5kR-jrIF-p4nD',
    title: 'Static',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/VALENTINO.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/VALENTINO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'Amyc-zdW5-W5VN',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/DISNEY-LUCA.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/DISNEY.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '9nYk-fR68-DCML',
    title: 'Mouse Over',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/48-RTL-PLL.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/RTL.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '8o0s-1Ubf-obr2',
    title: 'Reactive Scroll',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/49-MarcoPolo.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/MARCOPOLO.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'YNCq-9UlF-fxEd',
    title: 'Mouse Over',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/Geolino.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/Geolino.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'tvXS-wwmq-o5UA',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/51-RTL-Bachelor.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/RTL.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ulJs-d60w-fjnQ',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/52-Nintendo-Zelda.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/NINTENDO.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ZUkU-lEhU-yFU1',
    title: 'Interactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/ProSieben-WSMDS.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/ProSieben.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'Qrld-5VtU-a6Fn',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/54-Nintendo-Zelda.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/NINTENDO.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '6xbn-hxXC-4cla',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/HENDRICKS.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/HENDRICKS.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ANrn-M5VY-J45P',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/56-Kia-EV6.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/KIA.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'eZay-q8cA-JS9a',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/ASUS-ROG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/ASUS-ROG.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '8YMz-T9xt-wHu2',
    title: 'Interactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/58-Shark-Flexstyle.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/SHARK.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'udnA-v9eH-hSuG',
    title: 'Interactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/NESPRESSO-VERTUO-POP.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/NESPRESSO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'Ew4G-y5l0-DD4S',
    title: 'Interactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/ICE.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/ICE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'Ckwk-4Gyb-mdac',
    title: 'Interactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/HASBRO-PEPPA-PIG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/HASBRO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'YnZj-5lCG-6nXb',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/BALVENIE.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/BALVENIE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'jHTB-Ht8p-vQlq',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/SPARKASSE.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/SPARKASSE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: '2WEq-tNsk-r8VQ',
    title: 'Inline to Sticky',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/AMAZON.png',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/AMAZON-PRIME-VIDEO.svg',
  },
];
