import { RefObject, useCallback, useLayoutEffect, useState } from 'react';

export const useScaleChildToFitParent = (
  parentRef: RefObject<HTMLDivElement> | undefined,
  childRef: { clientWidth: number; clientHeight: number } | undefined,
) => {
  const [scale, setScale] = useState(0);

  const RATIO_TOLERANCE = 0;

  const setMaxScale = (num: number) => {
    return setScale(Math.min(num, 1));
  };

  const getSizes = useCallback(() => {
    const { clientHeight: parentH = 0, clientWidth: parentW = 0 } = parentRef?.current ?? {};

    if (parentH === 0 || parentW === 0) {
      return;
    }

    const { clientHeight: childH = 0, clientWidth: childW = 0 } = childRef ?? {};

    const isPPortrait = parentH > parentW;
    const isCPortrait = childH > childW;

    if (isPPortrait && !isCPortrait) {
      const wRatio = parentW / childW - RATIO_TOLERANCE;
      setMaxScale(wRatio);
    }

    if (isPPortrait && isCPortrait) {
      const wRatio = parentW / childW - RATIO_TOLERANCE;
      const hRatio = parentH / childH - RATIO_TOLERANCE;

      if (childH * (wRatio + RATIO_TOLERANCE) >= parentH) {
        setMaxScale(hRatio);
      } else {
        setMaxScale(wRatio);
      }
    } else if (!isPPortrait && isCPortrait) {
      const hRatio = parentH / childH - RATIO_TOLERANCE;
      setMaxScale(hRatio);
    } else if (!isPPortrait && !isCPortrait) {
      const hRatio = parentH / childH - RATIO_TOLERANCE;
      const wRatio = parentW / childW - RATIO_TOLERANCE;

      if (childW * (hRatio + RATIO_TOLERANCE) >= parentW) {
        setMaxScale(wRatio);
      } else {
        setMaxScale(hRatio);
      }
    }
  }, [childRef, parentRef]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(getSizes);
    const parentElement = parentRef?.current;

    if (parentElement) {
      resizeObserver.observe(parentElement);
    }

    return () => {
      if (parentElement) {
        resizeObserver.unobserve(parentElement);
      }
    };
  }, [getSizes, parentRef]);

  return { scale };
};
