import { PropsWithChildren, useEffect } from 'react';

import { E_PREVIEW_THEME } from '@common/enums';
import { useThemeStore } from '@common/stores';
import HelmetHead from '@components/Head';

import type { TPageProps } from './types';

const Page = (props: PropsWithChildren<TPageProps>) => {
  const { children, head } = props;
  const theme = useThemeStore((state) => {
    return state.theme;
  });

  // Add theme name to the <body> tag
  useEffect(() => {
    if (typeof theme === 'string') {
      document.body.classList.add(theme);
    }

    return () => {
      if (typeof theme === 'string') {
        document.body.classList.remove(theme);
      }
    };
  }, [theme]);

  return (
    <>
      <HelmetHead {...head} />
      {theme !== E_PREVIEW_THEME.NEUTRAL && <div className="logo">YOC</div>}
      {children}
    </>
  );
};

export default Page;
