export type TButtonArrowProps = {
  direction?: TArrowDirection;
  disabled?: boolean;
  className?: string;
  color?: string;
  scale?: number;
  onClick?: () => void;
};

export type TArrowDirection = 'top' | 'right' | 'bottom' | 'left';

export enum E_ARROW_TEXT {
  right = '→',
  top = '↑',
  bottom = '↓',
  left = '←',
}
