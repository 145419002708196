import { ReactElement } from 'react';
import Cookie from 'js-cookie';

import { COOKIE_EXPIRE_DAYS } from './constants';
import { E_COOKIE_ACTION_TYPE, TCookieActionType } from './types';

/**
 * Allows work with cookies based on URL parameters.
 * Type of operation (ADD, REMOVE, CHECK).
 * Example of adding cookies: /cookie/add?isTestCookie=true&theme=default
 * Example of removing cookies: /cookie/remove?isTestCookie&theme
 * Example of checking cookies: /cookie/check?isTestCookie&theme
 */
export const getCookieMessage = (actionType: TCookieActionType) => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryParamsLength = Array.from(queryParams).length;
  const messages: ReactElement[] = [];

  switch (actionType) {
    case E_COOKIE_ACTION_TYPE.ADD:
      if (queryParamsLength > 0) {
        queryParams.forEach((value, key) => {
          Cookie.set(key, value, {
            expires: COOKIE_EXPIRE_DAYS,
            secure: true,
            sameSite: 'strict',
            path: '/',
          });
          messages.push(<>The <code>{key}</code> cookie was successfully set 🙌</>);
        });
      } else {
        messages.push(
          <>Please provide a key(s) with value for adding as <code>/add?keyName</code>
            <br />or <code>/add?keyName&anotherKeyName</code> 🧐️</>
        );
      }
      break;

    case E_COOKIE_ACTION_TYPE.REMOVE:
      if (queryParamsLength > 0) {
        queryParams.forEach((value, key) => {
          Cookie.remove(key);
          messages.push(<>The <code>{key}</code> cookie has been successfully deleted 🗑️</>);
        });
      } else {
        messages.push(
          <>Please provide a key(s) for removing as <code>/remove?keyName</code>
            <br />or <code>/remove?keyName&anotherKeyName</code> 🧐️</>
        );
      }
      break;

    case E_COOKIE_ACTION_TYPE.CHECK:
      if (queryParamsLength > 0) {
        queryParams.forEach((value, key) => {
          if (key) {
            const cookieValue = Cookie.get(key);
            cookieValue
              ? messages.push(<><code>{key}</code> key has <code>cookieValue</code> value</>)
              : messages.push(<><code>{key}</code> key was not found or it value is empty 🤕</>);
          }
        });
      } else {
        messages.push(
          <>Please provide a key(s) for checking as <code>/check?keyName</code>
            <br />or <code>/cookie/check?keyName&anotherKeyName</code> 🧐️</>
        );
      }
      break;

    default:
      break;
  }

  return messages;
};
