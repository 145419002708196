import { memo, useCallback, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Route } from 'type-route';

import { E_COUNTRY_CODE } from '@common/enums';
import { useDmexco } from '@common/hooks';
import type { TSlotId } from '@common/types';
import Header from '@components/Header';
import Page from '@components/Page';
import { ADS_COLLECTION } from '@configs/index';
import { routes, useRoute } from '@navigation/router';
import Menu from '@pages/Showroom/Menu';
import MobileShowroom from '@pages/Showroom/Mobile';
import View from '@pages/Showroom/View';

import { getDefaultSlotId } from './helpers';

import style from './styles.module.scss';

export const Showroom = () => {
  const route = useRoute() as Route<typeof routes.collection>;

  const collectionId = route.params.collectionId || E_COUNTRY_CODE.GERMANY;
  const collectionData = ADS_COLLECTION[collectionId];

  const isDMEXCOMode = useDmexco();
  const forceMobile = isMobileOnly || isDMEXCOMode;

  const initSlotId = getDefaultSlotId(collectionData);
  // TODO: if slotId in url is not valid activeSlotId stays being invalid url
  // even after being redirected to the default ad url.
  const activeSlotId = route.params.slotId ?? initSlotId;

  // URL management
  useEffect(() => {
    if (collectionId && initSlotId) {
      // add `initSlotId` to URL if:
      // 1. there is no `slotId` at all, just the country id, e.g. `/de`
      // 2. provided in the route `slotId` is not exist in current Ad Collection
      if (
        !route.params.slotId ||
        !collectionData.ads.some((ad) => {
          return ad.slotId === route.params.slotId;
        })
      ) {
        routes
          .collection({
            collectionId,
            slotId: initSlotId,
          })
          .replace();
      }
    }
  }, [collectionData.ads, collectionId, initSlotId, route.params.slotId]);

  // MENU
  const onMenuChange = useCallback(
    (slotId: TSlotId | undefined) => {
      routes
        .collection({
          collectionId,
          slotId,
        })
        .push();
    },
    [collectionId]
  );

  if (forceMobile) {
    return <MobileShowroom />;
  }

  return (
    <Page>
      <div className={style.page}>
        <Header
          tag={collectionData.tag}
          navigation={collectionData.type === 'collection' ? false : undefined}
        />
        <div className={style.aside}>
          <Menu
            key={collectionId}
            creatives={collectionData.ads}
            activeSlotId={activeSlotId}
            onCreativeChange={onMenuChange}
          />
        </div>
        <div className={style.content}>
          <div className="main">
            <View
              slotId={activeSlotId}
              precache={collectionData.ads.flatMap((ad) => {
                return ad.slotId.split('+'); // TODO: move split sign into the var
              })}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default memo(Showroom);
