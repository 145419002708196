import { forwardRef, memo, ReactNode, RefObject, useEffect, useRef } from 'react';
import { Carousel, CarouselRef, CarouselSettings } from 'yoc-ui-library';

import { useDmexco } from '@common/hooks';
import { setActiveSlideNumber, useGalleryStore } from '@common/stores';
import ArrowButton from '@components/ArrowButton';
import { useScaleChildToFitParent } from '@components/Gallery/hooks';

import type { TGalleryProps } from './types';

import './styles.scss';

const Gallery = forwardRef<HTMLDivElement, TGalleryProps>((props, ref) => {
  const { slides, isPortrait: isPortraitProp = false } = props;

  const carouselRef = useRef<CarouselRef>(null);

  const { activeSlideNumber } = useGalleryStore();

  const isDMEXCOMode = useDmexco(); // https://yocmobile.atlassian.net/browse/DC-328
  const isPortrait = isPortraitProp || isDMEXCOMode;

  const slideItemName = 'slide-item';

  // TEST IT
  // Hardcoded values of the biggest slide (Desktop and Mobile)
  // to avoid different slide sizes when switching between galleries
  const maxSlideWH: { clientWidth: number; clientHeight: number } | undefined = {
    clientHeight: 740,
    clientWidth: 1386,
  };

  // const refBasedSlidesList = carouselRef.current?.innerSlider?.list?.querySelectorAll(`[class*="${slideItemName}"]`);
  // if (refBasedSlidesList && refBasedSlidesList.length > 0) {
  //   maxSlideWH = Array.from(refBasedSlidesList).reduce(
  //     (acc, current: Element) => {
  //       return {
  //         clientWidth: acc.clientWidth > current.clientWidth ? acc.clientWidth : current.clientWidth,
  //         clientHeight: acc.clientHeight > current.clientHeight ? acc.clientHeight : current.clientHeight,
  //       };
  //     },
  //     {
  //       clientWidth: 0,
  //       clientHeight: 0,
  //     },
  //   );
  // }

  const { scale } = useScaleChildToFitParent(ref as RefObject<HTMLDivElement>, maxSlideWH);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current?.innerSlider?.list?.style.setProperty('--preview-gallery-scale', `${scale}`);
    }
  }, [carouselRef, scale]);

  useEffect(() => {
    return () => {
      setActiveSlideNumber(0);
    };
  }, [carouselRef]);

  if (!slides || slides.length === 0) {
    return null;
  }

  const settings: CarouselSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    centerPadding: '0',
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: !isPortrait,
    vertical: isPortrait,
    beforeChange: (currentSlide: number, nextSlide: number) => {
      setActiveSlideNumber(nextSlide);
    },
    appendDots: (dots: ReactNode) => {
      return (
        // WARNING: do NOT remove Fragment (<></>) below! it provides a correct layout
        <>
          <div className="gallery-nav">
            <ArrowButton
              className="arrow-button"
              direction={isDMEXCOMode ? 'top' : 'left'}
              color="var(--page-color-accent)"
              disabled={activeSlideNumber === 0}
              onClick={() => {
                carouselRef?.current?.prev();
              }}
            />
            <ul className="dots">{dots}</ul>
            <ArrowButton
              className="arrow-button"
              direction={isDMEXCOMode ? 'bottom' : 'right'}
              color="var(--page-color-accent)"
              disabled={activeSlideNumber === slides.length - 1}
              onClick={() => {
                carouselRef?.current?.next();
              }}
            />
          </div>
        </>
      );
    },
  };

  return (
    <Carousel
      {...settings}
      className={`slider ${isPortrait ? 'vertical-mode' : ''}`.trim()}
      ref={carouselRef}
    >
      {slides?.map((item, index) => {
        return (
          <div
            key={index}
            className={slideItemName}
          >
            {item}
          </div>
        );
      })}
    </Carousel>
  );
});

Gallery.displayName = 'Gallery';

export default memo(Gallery);
