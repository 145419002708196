import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_PL: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'fHsN-8Yzw-IKPO',
    title: '360° Swipe',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/KIA.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/Kia_biale.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'HStN-A7H3-t6r4',
    title: '3D',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/CColaEnergy.jpg',
    logoUrl: 'https://cdn.yoc.com/CH/showroom/ch/compressed/logo/Coca_Cola_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '9KhP-92Lv-Zu5u',
    title: '3D Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/Lego1.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/LEGO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'FLEi-ufjC-FRwA',
    title: 'Augumented Reality',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/Bossch.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/SiemensLogoBiale.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'yd1I-K7eq-ApfF',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/fryty.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/McD_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'YKdt-F2dF-CZB5',
    title: 'Hot Spots',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/Nisan.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/nissan.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'zfdK-HTwd-mS3A',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/stacna.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/orlen.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '6TVY-a8zM-txL9',
    title: 'Poll',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/wigiilia.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/krakus.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '0j7r-7yi1-8koF',
    title: 'Slider Bar',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/bro.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/okocim.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'VChY-0q8Q-BcUa',
    title: 'Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/hogwart.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/LEGO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'zj9i-FfM2-JlTs',
    title: 'Reactive + Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/nivea.png',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/nivea.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'X15s-sC4X-0D85',
    title: 'Reactive + Hot Spots',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/renault.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/reno.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'rs5E-nZNq-91LJ',
    title: 'Static',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/xiao.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/logosy/xioa.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'kkON-jD3f-95o1',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/foxxx.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/fogz.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'himV-YHIj-9KYc',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/gumki.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/durex.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'l59R-l11Q-vx9Y',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/fryty.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/McD_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'U3xo-TJpj-kl4m',
    title: 'Dynamic Battery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/Ksiezyc.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/enefit.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '0mMQ-42uE-OMNV',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/konie.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/ccc.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'K94f-LCMW-d7we',
    title: 'Map Integration',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/diemanty.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/geo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'WcBJ-QRKV-XtnQ',
    title: 'Gallery',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/american.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/kfc.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'sJI4-FP4F-Im6N',
    title: 'Poll',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/garage.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/seth.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'E1Aa-UJGs-HbRL',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/infkakt.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/infakt.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'DGQS-xshB-iKLN',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/background/Orlen.jpg',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/orlen.svg',
  },
];
