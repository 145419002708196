import { memo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Route } from 'type-route';

import { E_CUSTOM_CODE, E_PREVIEW_MODE, E_SHOWROOM_THEME } from '@common/enums';
import { getAdCategoryBySlotId, isSlotIdValid } from '@common/helpers';
import { useDmexco } from '@common/hooks';
import { themeController, useAdsStore, useThemeStore } from '@common/stores';
import Page from '@components/Page';
import { routes, useRoute } from '@navigation/router';
import { getPreviewTemplateUrl } from '@pages/AdPreview/helpers';
import View from '@pages/Showroom/View';

const AdPreview = () => {
  const route = useRoute() as Route<typeof routes.preview>;
  const slotIdString = route.params.slotId; // single or multiple slotIds

  const isSingleSlotId = isSlotIdValid(slotIdString);

  // const slotIds = useMemo(() => {
  //   return splitSlotIdsFromUrl(slotIdString).filter((slotId) => {
  //     return isSlotIdValid(slotId);
  //   });
  // }, [slotIdString]);

  const adsDataCollection = useAdsStore((state) => {
    return state.adsData;
  });
  const isDMEXCOMode = useDmexco();

  // THEME
  const theme = useThemeStore((state) => { return state.theme; });
  if (isSingleSlotId && adsDataCollection?.[slotIdString]) {
    themeController(adsDataCollection[slotIdString].previewWebsiteLayout);
  }

  // The logic to forward automatically to cb.* once the cf.* URL is opened on a mobile device
  // https://yocmobile.atlassian.net/browse/TSP-7228
  // TODO: should we check theme or isDMEXCO?
  if (isMobileOnly && isSingleSlotId && adsDataCollection?.[slotIdString] && theme !== E_SHOWROOM_THEME.WEBSITE) {
    const template = getPreviewTemplateUrl(E_PREVIEW_MODE.MOBILE, adsDataCollection[slotIdString].previewWebsiteLayout);
    // redirect for Mobile as soon as possible
    window.location.href = `${template}/${slotIdString}`;
    return null;
  }

  return (
    <Page>
      {isDMEXCOMode && (
        <div className="dmexco-ad-category">
          <button
            className="back-arrow"
            onClick={() => {
              // Temporary solution for dmexco since it runs on /website route
              routes.collection({ collectionId: E_CUSTOM_CODE.WEBSITE, slotId: slotIdString }).push();
            }}
          />
          <p>{getAdCategoryBySlotId(slotIdString)}</p>
        </div>
      )}
      <View slotId={slotIdString} />
    </Page>
  );
};

export default memo(AdPreview);
