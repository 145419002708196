import { FunctionComponent, memo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useThemeStore } from '@common/stores';

import { headConfig } from './configs';
import type { THelmetHeadProps } from './types';

const HelmetHead: FunctionComponent<THelmetHeadProps> = (props) => {
  const { title } = props;

  const theme = useThemeStore((state) => {
    return state.theme;
  });

  return (
    <Helmet title={title ?? headConfig[theme].title}>
      {headConfig[theme].head}
    </Helmet>
  );
};

export default memo(HelmetHead);
