import { FunctionComponent, memo } from 'react';
import { QRCode } from 'yoc-ui-library';

import { TQRCodeLinkProps } from './types';

import './styles.scss';

const QRCodeLink: FunctionComponent<TQRCodeLinkProps> = (props) => {
  const { className = '', value, size = 90 } = props;

  return (
    <a
      className={`qr ${className}`.trim()}
      href={value}
      target="_blank"
      rel="noreferrer"
      data-testid="qr"
    >
      <QRCode
        value={value}
        size={size}
      />
    </a>
  );
};

export default memo(QRCodeLink);
