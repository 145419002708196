import { FunctionComponent, memo, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Route } from 'type-route';
import { Modal, Switch } from 'yoc-ui-library';

import { E_PREVIEW_MODE, E_SHOWROOM_THEME } from '@common/enums';
import { useDmexco } from '@common/hooks';
import { setIsModalOpen, useModalStore, useThemeStore } from '@common/stores';
import Header from '@components/Header';
import { routes, useRoute } from '@navigation/router';

import { TSwitcherProps } from './types';

import './styles.scss';

const Switcher: FunctionComponent<TSwitcherProps> = (props) => {
  const {
    previewMode = E_PREVIEW_MODE.MOBILE,
    isSwitchVisible = true,
    previewUrl = '',
    changePreviewMode = () => {console.info('changePreviewMode is not set');},
  } = props;

  const {
    name: routeName,
  } = useRoute() as Route<typeof routes.websitePreview>;

  const { isModalOpen } = useModalStore();

  // THEME
  const { theme } = useThemeStore();
  const isDMEXCOMode = useDmexco();
  const isWebsite = theme === E_SHOWROOM_THEME.WEBSITE;

  const landscape = window.matchMedia('(orientation: landscape)');

  useEffect(() => {
    const handleOrientationChange = () => {
      if (landscape.matches) {
        changePreviewMode(E_PREVIEW_MODE.DESKTOP);
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      landscape.addEventListener('change', handleOrientationChange);
    }

    return () => {
      if (landscape) {
        landscape.removeEventListener('change', handleOrientationChange);
      }
    };
  }, [isModalOpen, landscape, changePreviewMode]);

  return (
    <div className={`switcher ${routeName === 'websitePreview' ? 'preview' : ''}`.trim()}>
      {isWebsite ? (
        (isMobileOnly && !isDMEXCOMode) ? (
          <>
            <Header />
            <Modal
              className={'modal desktop-turn'}
              centered
              open={isModalOpen}
              onCancel={() => {return setIsModalOpen(false);}}
              onOk={
                () => {
                  setIsModalOpen(false);
                  changePreviewMode(E_PREVIEW_MODE.DESKTOP);
                }
              }
            >
              <p>To view this desktop case,
                rotate your screen 90° degrees to landscape mode for an immersive experience.</p>
            </Modal>
            <Switch
              className={`switch ${!isSwitchVisible ? 'hidden' : ''}`.trim()}
              checked={previewMode === E_PREVIEW_MODE.DESKTOP}
              checkedChildren="Desktop"
              unCheckedChildren="Mobile"
              onClick={() => {
                if (previewMode === E_PREVIEW_MODE.DESKTOP){
                  changePreviewMode(E_PREVIEW_MODE.MOBILE);
                }
                if (previewMode === E_PREVIEW_MODE.MOBILE){
                  setIsModalOpen(true);
                }
              }}
            />
          </>
        ) : (
          <>
            <div className={`switch ${!isSwitchVisible ? 'hidden' : ''}`.trim()}>
              <span>Mobile</span>
              <Switch
                disabled={!isSwitchVisible}
                checked={previewMode === E_PREVIEW_MODE.DESKTOP}
                onClick={() => {
                  changePreviewMode(
                    previewMode === E_PREVIEW_MODE.MOBILE ? E_PREVIEW_MODE.DESKTOP : E_PREVIEW_MODE.MOBILE
                  );
                }}
              />
              <span>Desktop</span>
            </div>
            <button
              className="button"
              disabled={previewMode !== E_PREVIEW_MODE.DESKTOP}
              onClick={(event) => {
                event.preventDefault();
                window.open(previewUrl, '_blank');
              }}
            >
              Demo
            </button>
          </>
        )) : (
        <>
          {isSwitchVisible && (
            <>
              <button
                className={`button mobile ${previewMode === E_PREVIEW_MODE.MOBILE ? 'selected' : ''}`.trim()}
                onClick={() => {
                  changePreviewMode(E_PREVIEW_MODE.MOBILE);
                }}
              >
                Mobile
              </button>
              <button
                className={`button desktop ${previewMode === E_PREVIEW_MODE.DESKTOP ? 'selected' : ''}`.trim()}
                onClick={() => {
                  changePreviewMode(E_PREVIEW_MODE.DESKTOP);
                }}
              >
                Desktop
              </button>
            </>
          )}
          {previewMode === E_PREVIEW_MODE.DESKTOP && (
            <button
              className="button fullscreen"
              onClick={(event) => {
                event.preventDefault();
                window.open(previewUrl, '_blank');
              }}
              data-testid="fullscreenButton"
            >
              Fullscreen
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default memo(Switcher);
