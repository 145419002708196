import { useEffect } from 'react';

import { getCountryCode } from '@common/helpers';
import { routes, useRoute } from '@navigation/router';
import AdPreview from '@pages/AdPreview';
import CookiePage from '@pages/CookiePage';
import NoMatchPage from '@pages/NoMatch';
import Showroom from '@pages/Showroom';

const App = () => {
  const route = useRoute();

  useEffect(() => {
    switch (route.name) {
      // redirect from root to country-specific (based on time zone) route
      case 'home':
        routes.collection({ collectionId: getCountryCode() }).replace();
        break;
      default:
    }
  }, [route.name]);

  return (
    <>
      {route.name === 'collection' && <Showroom />}
      {(route.name === 'preview' || route.name === 'websitePreview') && <AdPreview />}
      {route.name === 'cookie' && <CookiePage />}
      {route.name === false && <NoMatchPage />}
    </>
  );
};

export default App;
