import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_YUDS: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: 'wKsy-nw4e-lUc7+Gdf7-6yVl-Dgx7+kUSi-G0Q8-uCub',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_tchibo.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-tchibo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: 'wAzY-mS6H-Kyln+wGbT-HGRQ-oTMe+kUSi-G0Q8-uCub',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/personalshop-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/personalshop-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'wKsy-nw4e-lUc7',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_tchibo.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-tchibo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'wAzY-mS6H-Kyln',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_tchibo.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-tchibo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Gdf7-6yVl-Dgx7',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_tchibo.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-tchibo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'wGbT-HGRQ-oTMe',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_tchibo.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-tchibo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'kUSi-G0Q8-uCub',
    title: 'Drag & Drop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_tchibo.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-tchibo.png',
  },
];
