import React from 'react';
import { Route } from 'type-route';

import Page from '@components/Page';
import { routes, useRoute } from '@navigation/router';

import { getCookieMessage } from './helpers';

const CookiePage = () => {
  const route = useRoute() as Route<typeof routes.cookie>;

  const messages = getCookieMessage(route.params.actionType);

  return (
    <Page>
      <div className="main">
        {messages.map((message, index) => {
          return <p key={index}>{message}</p>;
        })}
      </div>
    </Page>
  );
};
export default CookiePage;
